body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error-captcha{
	color:red;
	margin-bottom: 20px;
  font-weight: 900;
  font-size: 18px;
}

.label-meic {
  font-size: 20px;
  color: #7db439;
  font-weight: bold;
}

.label-ast {
  font-size: 20px;
  color: red;
  font-weight: bold;
}

.label-estudio {
  font-size: 27px;
  color: #557A27;
  font-weight: bold;
}

.titulo {
  font-size: 35px;
  color: #7db439;
  margin: 0 20px 0 20px;
  font-weight: bold;
}

.imagen-meic {
  margin: 0 0 10px 20px;
}

.fondo-azul{
  background-color: #00B5AE;
}

.cuerpo{
  /* Para poder fijar el pie de página con posición absoluta */
  position: relative;
  /* Medida mínima */
  min-height: 100vh;
}

.centrar{
  text-align: center;
}

.texto-blanco{
  color: white;
  font-size: 17px;
}

.color-azul-meic{
  background-color: #002D72;
}

.iconos{
  font-size: 30px;
  color: white;
}

.texto-tabla{
 color: #557A27 !important;
}


.disabled {
  opacity: 0.4;
  pointer-events: none;
}


.gris-fondo{
  background-color: #F8F9FA;
}

.head-arriba{
  background-color: rgb(230, 231, 232, 0.4);
  border-bottom: 1px solid #00b5ae29;
}

.p-row-odd{
  background: #F1F1F3 !important;
}

.blockui-demo .p-panel p {
  line-height: 1.5;
  margin: 0;
}
.blockui-demo button {
  margin-right: 0.5rem;
}

.version{    
  font-size: 10px;
  color: #00aba4;
}


@media (max-width: 800px){  /*1200*/

	.mostrarPequeño{
		width: 80%;
	}

}